import React, { useState, useEffect } from 'react';
import { Paper, IconButton, Dialog, Box, CircularProgress } from '@material-ui/core';
import {
	KeyboardArrowDown as ArrowDownIcon,
	KeyboardArrowUp as ArrowUpIcon,
	RemoveRounded as AlarmIcon,
	DoneRounded as NoAlarmIcon,
	ThreeDRotation as ThreeDIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { gql, useLazyQuery } from '@apollo/client';
import { format as formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getFormattedNumber, isValueInsideThresholds } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import SensorList from './SensorList';
import CustomButton from './CustomButton';
import ThreeSixtyView from './ThreeSixtyView';

const GET_DIGITALTWIN_TAGS = gql`
	query ($filter: DigitalTwinTagFilter!) {
		getDigitalTwinTags(filter: $filter) {
			digitaltwintagid
			digitaltwinid
		}
	}
`;
const GET_DIGITALTWIN_PERSPECTIVES = gql`
	query ($filter: DigitalTwinPerspectiveFilter!) {
		getDigitalTwinPerspectives(filter: $filter) {
			digitaltwinperspectiveid
			digitaltwinid
			sweep
			rotationx
			rotationy
			zoom
		}
	}
`;

/**
 * Draws a card for a sensor-group that can be expanded to view a list of all sensors belonging to the group
 * @param {object} sensorGroup : To be drawn
 * @param {string | undefined} selectedSensorId : Expands the sensor group if set, further functionality handled by SensorList
 */
function SensorGroupCard(props) {
	const [isExpanded, setIsExpanded] = useState();
	const [show360Popup, setShow360Popup] = useState(false);
	const [digitalTwinSelection, setDigitalTwinSelection] = useState(); // Format: {digitalTwin: object, tagId: number, perspective: object}
	const [isAlarmActive, setIsAlarmActive] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const [, _forceRerender] = useState();
	function forceRerender() {
		_forceRerender({});
	}

	const [getDigitalTwinTags] = useLazyQuery(GET_DIGITALTWIN_TAGS, {
		onCompleted: ({ getDigitalTwinTags }) => {
			if (!getDigitalTwinTags[0]) return;
			setShow360Popup(true);
			setDigitalTwinSelection({
				digitalTwin: props.digitalTwins.find(dt => dt.digitaltwinid === getDigitalTwinTags[0].digitaltwinid),
				tagId: getDigitalTwinTags[0].digitaltwintagid,
			});
		},
		fetchPolicy: 'no-cache',
	});
	const [getDigitalTwinPerspectives] = useLazyQuery(GET_DIGITALTWIN_PERSPECTIVES, {
		onCompleted: ({ getDigitalTwinPerspectives }) => {
			if (!getDigitalTwinPerspectives[0]) return;
			setShow360Popup(true);
			setDigitalTwinSelection({
				digitalTwin: props.digitalTwins.find(dt => dt.digitaltwinid === getDigitalTwinPerspectives[0].digitaltwinid),
				perspective: getDigitalTwinPerspectives[0],
			});
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (props.selectedSensorId) {
			setIsExpanded(true);
			setTimeout(() => {
				const selectedGrpCard = document.getElementById('sg-' + props.sensorGroup.sensorgroupid);
				if (selectedGrpCard) selectedGrpCard.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}, 600); // Delay required for scrolling to work
		}
		// eslint-disable-next-line
	}, [props.selectedSensorId]);

	useEffect(() => {
		console.log('SensorGroupCard props.sensorGroup.sensors', props.sensorGroup.sensors);
		console.log('SensorGroupCard props.alarmSensorIds', props.alarmSensorIds);
		console.log('SensorGroupCard props.waitForAlarm', props.waitForAlarm);
		if (props.sensorGroup?.sensors && (props?.alarmSensorIds || props?.waitForAlarm == false)) {
			if (props?.alarmSensorIds) {
				const alarmActive = props.sensorGroup.sensors.some(sen => props.alarmSensorIds.includes(sen.sensorid));
				setIsAlarmActive(alarmActive);
			} else {
				const alarmActive = props.sensorGroup.sensors.some(sen => isValueInsideThresholds(sen.value, sen.thresholds));
				setIsAlarmActive(alarmActive);
			}
			setIsLoading(false);
		}
	}, [props.alarmSensorIds, props.sensorGroup.sensors]);

	function groupIsHomogenous() {
		const unit = props.sensorGroup?.sensors?.[0]?.unit;
		return !props.sensorGroup?.sensors?.some(sen => sen.unit !== unit);
	}

	const alarmIconStyle = { transform: 'scale(1.5)', color: '#fffc', filter: 'drop-shadow(0 0 4px #0006)' };
	const average =
		props.sensorGroup?.sensors?.[0]?.unit && groupIsHomogenous()
			? getFormattedNumber(props.sensorGroup.sensors.reduce((sum, cur) => sum + cur.value, 0) / props.sensorGroup.sensors.length) +
				' ' +
				(props.sensorGroup.sensors[0]?.unit || '')
			: undefined;

	const { t } = useTranslation();

	if (isLoading) {
        return (
            <>
                <div style={{ margin: '0 1rem 0.8rem' }}>
                    <Paper
                        elevation={2}
                        id={'sg-' + props.sensorGroup.sensorgroupid}
                        style={{
                            position: 'relative',
                            display: 'grid',
                            gridTemplateColumns: '7rem 28.4% 29% auto',
                            width: '57.9rem',
                            height: '4rem',
                            border: 'solid',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            borderRadius: '0.4rem',
                            borderWidth: '0.05rem',
                            borderColor: '#00000015',
                            alignItems: 'center',
                        }}
                    >
                        <Paper
                            elevation={0}
                            style={{
                                height: 'inherit',
                                borderRadius: '0.4rem 0 0 0.4rem',
                                backgroundImage: 'linear-gradient(150deg, rgba(105, 186, 58, 1) 0%, rgba(56, 145, 18, 1) 100%)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
						>
                            <NoAlarmIcon style={alarmIconStyle} />
                        </Paper>

                        <div style={{ margin: '0.5rem 0 0 1.2rem' }}>
                            <h3 style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{props.sensorGroup.name}</h3>
                            <p style={{ marginTop: '0.1rem', color: '#999', fontSize: '80%' }}>
                                {t('sensorGroupCard.updated')}{' '}
                                {formatDate(new Date(), 'yyyy-MM-dd HH:mm')}
                            </p>
                        </div>
						<div //aligns the loading spinner to the right
							style={{ display: 'grid', gridTemplateColumns: '6.5rem auto', margin: '0.5rem 0 0 4.0rem' }}
						>
							<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0.5rem 0 0 5.2rem' }}>
								<CircularProgress />
							</Box>
						</div>
                    </Paper>
                    <CustomButton
                        icon={ArrowDownIcon}
                        text={t('sensorGroupCard.showSensor')}
                        onClick={() => setIsExpanded(!isExpanded)}
                        style={{
                            width: '9rem',
                            margin: '-0.5rem auto 0',
                            padding: '0.4rem 0 0 0',
                        }}
                    />
                </div>
            </>
        );
    }
	return (
		<>
			<div style={{ margin: '0 1rem 0.8rem' }}>
				<Paper
					elevation={2}
					id={'sg-' + props.sensorGroup.sensorgroupid}
					style={{
						position: 'relative',
						display: 'grid',
						gridTemplateColumns: '7rem 28.4% 29% auto',
						width: '57.9rem',
						height: '4rem',
						border: 'solid',
						borderLeft: 'none',
						borderBottom: 'none',
						borderRadius: '0.4rem',
						borderWidth: '0.05rem',
						borderColor: '#00000015',
					}}
				>
					<Paper
						elevation={0}
						style={{
							height: 'inherit',
							borderRadius: '0.4rem 0 0 0.4rem',
							backgroundImage: isAlarmActive
								? 'linear-gradient(150deg, rgba(255, 74, 81, 1) 0%, rgba(181, 0, 3, 1) 100%)'
								: 'linear-gradient(150deg, rgba(105, 186, 58, 1) 0%, rgba(56, 145, 18, 1) 100%)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{isAlarmActive ? <AlarmIcon style={alarmIconStyle} /> : <NoAlarmIcon style={alarmIconStyle} />}
					</Paper>

					{/* Title and date updated */}
					<div style={{ margin: '0.5rem 0 0 1.2rem' }}>
						<h3 style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{props.sensorGroup.name}</h3>
						<p style={{ marginTop: '0.1rem', color: '#999', fontSize: '80%' }}>
							{t('sensorGroupCard.updated')}{' '}
							{formatDate(
								props.sensorGroup?.sensors?.reduce((max, cur) => Math.max(max, cur.timestamp), new Date('2020')),
								'yyyy-MM-dd HH:mm'
							)}
						</p>
					</div>

					{/* Meter, warning, alarm counts */}
					<div style={{ display: 'grid', gridTemplateColumns: '6.5rem auto', margin: '0.5rem 0 0 1.2rem' }}>
						<p style={{ margin: '0 0', color: '#666', lineHeight: '1.5rem', gridColumn: '1 / 2' }}>
							{t('sensorGroupCard.numberOfSensors')}
							<br />
							{average !== undefined ? t('sensorGroupCard.average') : ''}
						</p>
						<p style={{ margin: '0 0', color: '#444', lineHeight: '1.5rem', gridColumn: '2 / 3' }}>
							{props.sensorGroup?.sensors?.length}
							<br />
							{average !== undefined ? average : ''}
						</p>
					</div>

					{/* Service dates */}
					{/* <div style={{ display: 'grid', gridTemplateColumns: '9.5rem auto', margin: '0.5rem 0 0.7rem 1.2rem' }}>
						<p style={{ margin: '0 0', color: '#666', lineHeight: '1.5rem', gridColumn: '1 / 2' }}>
							{t('sensorGroupCard.latestService')}
							<br />
							{t('sensorGroupCard.nextService')}
						</p>
						<p style={{ margin: '0 0', color: '#444', lineHeight: '1.5rem', gridColumn: '2 / 3' }}>
							-<br />-
						</p>
					</div> */}

					{/* 360 Fly-in button */}
					<IconButton
						onClick={() => {
							if (props.sensorGroup.digitaltwintagid)
								getDigitalTwinTags({ variables: { filter: { digitaltwintagids: [props.sensorGroup.digitaltwintagid] } } });
							else if (props.sensorGroup.digitaltwinperspectiveid)
								getDigitalTwinPerspectives({
									variables: { filter: { digitaltwinperspectiveids: [props.sensorGroup.digitaltwinperspectiveid] } },
								});
						}}
						style={{
							visibility:
								props.sensorGroup?.digitaltwintagid || props.sensorGroup?.digitaltwinperspectiveid ? 'visible' : 'hidden',
							position: 'absolute',
							top: '-0.3rem',
							right: '-0.3rem',
							padding: '0.2rem',
							background: '#fff',
							color: '#000a',
							boxShadow: '0 0 0.2rem #0008',
						}}
					>
						<ThreeDIcon style={{ transform: 'scale(0.8)' }} />
					</IconButton>
				</Paper>

				{isExpanded && (
					<SensorList
						sensors={props.sensorGroup.sensors}
						alarmSensorIds={props.alarmSensorIds}
						selectedSensorId={props.selectedSensorId}
						onUpdate={() => forceRerender()}
						style={{ marginTop: '0.5rem' }}
					/>
				)}

				<CustomButton
					icon={isExpanded ? ArrowUpIcon : ArrowDownIcon}
					text={isExpanded ? t('sensorGroupCard.hideSensor') : t('sensorGroupCard.showSensor')}
					onClick={() => setIsExpanded(!isExpanded)}
					style={{
						width: '9rem',
						margin: '-0.5rem auto 0',
						padding: '0.4rem 0 0 0',
					}}
				/>
			</div>

			<Dialog
				open={show360Popup}
				onClose={() => setShow360Popup(false)}
				onExited={() => setDigitalTwinSelection()}
				PaperComponent={props => <>{props.children}</>}
			>
				<ThreeSixtyView digitalTwin={digitalTwinSelection?.digitalTwin} selection={digitalTwinSelection} />
			</Dialog>
		</>
	);
}

export default connect(getStateVariables(STORE.digitalTwins))(SensorGroupCard);
